.error-page {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: black;
}
.error-logo {
    font-size: 4rem;
    padding: 2rem;
}
.error-page h1{
    color: #fff;
}
.error-msg  {
    color: #fff;
}
.home-link {
    color: var(--clr-orange);
    padding: 2rem;
    cursor: pointer;
    transition: var(--speed);
}
.home-link:hover {
    opacity: 0.75;
}