.sign__up__input {
  border: 3px solid white;
  display: grid;
  height: 2.4rem;
  border-radius: 0.8rem;
}
.sign__up__input.password {
  grid-template-columns: 1fr 2rem;
}

.sign__up__input.password input {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.sign__up__input.enter {
  grid-template-columns: 1fr 5rem;
}
.sign__up__input.enter button {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  font-weight: bolder;
}
button.disabled {
  opacity: 0.4;
}
.google__sign__in {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.google__sign__in img {
  height: 2.2rem;
}