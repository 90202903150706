input {
  background-color: var(--color-mode-secondary);
  width: 100%;
}
button {
  background-color: #0157ad;
  border-radius: 0.5rem;
  padding: 0.25rem .5rem;
  color: #FFF;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.success__box {
  background-color: rgb(3, 201, 3);
  color: #FFF;
  padding: 0.5rem 1rem;
  border-radius: 0.2rem;
}
.doc__viewer {
  width: 100%;
  min-height: 100vh;
}
.doc__viewer iframe {
  min-height: 100vh !important;
}
.view__image {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-mode-main);
}
.view__image div {
  backdrop-filter: blur(8000px);
  position: absolute;
  top: 2.5rem;
  right: 0rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;


}
.view__image img {
  max-width: 90%;
  max-height: 90%;
}