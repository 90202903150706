.modalBg{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.203);
    z-index: 1000;
  }
  .modalBg::before{
    content: '';
    position: absolute;
    background: var(--secondary-bg);
    transform: rotate(45deg);
  }
  .modalBg.active{
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: var(--speed) ease;
  }
  .modalBg.inactive{
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: var(--speed) ease;
  }