/* head center with span */
.head-text-centre-span-right {
  display: flex;
  justify-content: center;
  padding: 0.75rem;
  position: relative;
  width: 100%;
  align-items: center;
  gap: 1rem;
  text-transform: uppercase;
}
.head-text-centre-span-right span, .head-text-centre-span-right button {
  position: absolute;
  right: 0.2rem;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: var(--speed);
}
.head-text-centre-span-right span:hover {
  opacity: 0.7;
}
.head-text__larger--font {
  font-size: 1.2rem;
  font-weight: 700;
  font-family: var(--font-family);
}

/* pop up */
.pop-up-overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.265);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20000;
}
.pop-up {
  background-color: transparent !important;
  backdrop-filter: blur(70px);
  border-radius: 0.4rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 300;
  border: 2px solid transparent;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.open__closed--sidebar {
  border-right: 3px solid lightgray;
  border-bottom: 3px solid lightgray;
  border-bottom-right-radius: 0.4rem;
  padding: 0.125rem 0.5rem;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  z-index: 10;
}
.open__closed--sidebar span{
  height: 2rem;
}

/* centered div flex */
.center__flex {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.1rem;
}
/* flex end div */
.flex__end {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

/* element hover */
.span__hover {
  cursor: pointer;
  transition: var(--speed);
}
.span__hover:hover {
  opacity: 0.7;
}

/* page padding */
.page__padding {
  padding: 1rem 2rem;
}
@media screen and (max-width: 750px) {
  .page__padding {
    padding: 3.5rem 0.5rem 0.5rem 0.5rem;
  }
}

/* dash container */
.dashboard__container {
  position: relative;
  border-radius: 0.4rem;
  background-color: var(--color-mode-secondary);
}
.graph__container {
  position: relative;
}
.graph__container h5 {
  position: absolute;
  top: 0;
}
.graph__container canvas {
  height: 100% !important;
  width: 100% !important;
}
.search__typed {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.top__links {
  border-left: 3px solid lightgray;
  border-bottom: 3px solid lightgray;
  border-bottom-left-radius: 0.4rem;
  background-color: var(--color-mode-main);
  padding: 0.125rem 0.5rem;
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  z-index: 10;
  color: var(--color-text);
}
@media screen and (max-width: 600px) {
    .top__links {
      flex-direction: row;
      gap: 1rem;
    }
}
.top__links span {
  height: 2rem;
}


.maps__page {
  width: 100%;
}
.maps__page div {
  border-radius: 0.4rem;
}
.maps__page #maps {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
@media screen and (max-width: 1200px) {
  .maps__page {
    height: 80vh;
    width: calc(100vw - 4rem);
  }
}
@media screen and (max-width: 750px) {
  .maps__page {
    height: 80vh;
    width: calc(100vw - 1rem);
  }
}

