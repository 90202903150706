.siteHub__app--sidebar {
  height: 100vh;
  border-right: 3px solid var(--color-mode-secondary);
  overflow-y: scroll;
  background-color: var(--color-mode-main);
  flex: 1;
  transition: 0.3s;
}
.color__white {
  background-color: #FFFFFF;
}
.open__closed--sidebar {
  color: var(--text-color);
}
@media screen and (max-width: 1200px) {
  .split__controls, .split__options {
    display: none !important;
  }
  .side__bar__overflow {
    max-height: 80vh;
    overflow: scroll;
  }
}
.side__header {
  position: relative;
  width: 100%;
  text-align: center;
  border-bottom: 2px solid var(--color-mode-secondary);
  margin-bottom: 1rem;
}
.side__header h4 {
  padding: 0.8rem;
}
.side__header div {
  position: absolute;
  top: 0;
  right: 0;
  border-left: 2px solid var(--color-mode-secondary);
  height: 100%;
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  color: #0156ab;
}


.sidebar__label{
  padding-left: 0.5rem;
  color: var(--clr-grey-4);
  font-weight: bold;
}
.sidebar__links {
  padding: 0.75rem;
}
.sidebar__link {
  padding: 0.4rem;
  cursor: pointer;
  transition: var(--speed);
  border-radius: 0.4rem;
  gap: 1rem;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  font-size: 0.9rem;
  color: grey;
}
.inventory__sidebar span {
  padding: 0.4rem;
  cursor: pointer;
  transition: var(--speed);
  border-radius: 0.4rem;
  gap: 1rem;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  font-size: 0.9rem;
  color: grey;
}
.inventory__sidebar {
  display: flex;
  flex-direction: column;
  padding-left: 3rem;
  transition: var(--speed);
}
.sidebar__link:hover, .inventory__sidebar span:hover  {
  transition: var(--speed);
  background-color: var(--color-mode-secondary);
  opacity: 0.8;
}
.sidebar__link--active {
  transition: var(--speed);
  background-color: var(--color-mode-secondary);
}

.sidebar__color {
  color: #0156ab;
}

.sitehub__closed__links {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.sitehub__closed__links div {
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0156ab;
  font-size: 1.3rem;
  padding: 0.125rem 0;
  border-bottom: 1px solid var(--color-mode-secondary);
  transition: 0.5s;
}

.active__close {
  color: var(--text-color) !important;
  background-color: var(--color-mode-secondary) !important;
}
.open__closed {
  display: none;
}
@media screen and (max-width:1000px) {
  .siteHub__app--sidebar {
    position: fixed;
    left: -3rem;
    overflow: scroll;
    padding-left: 3rem;
    padding-top: 2.5rem;
    z-index: 4;
  }
  .sidebar__link {
    margin-bottom: 0.25rem;
    z-index: 2000;
    position: relative;
  }
  .open__closed {
    display: flex;
    position: fixed;
    left: 0rem;
    top: 2.5rem;
    color: #0156ab;
    background-color: var(--color-mode-secondary);
    border-bottom: 2px solid var(--color-mode-main);
    border-right: 2px solid var(--color-mode-main);
    z-index: 20;
    font-size: 1.2rem;
    padding: 0.5rem 0.125rem;
    border-bottom-right-radius: 0.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    color: #0156ab;
  }
}