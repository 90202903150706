.loading-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
  background-color: rgba(0, 0, 0, 0.139);
  position: fixed;
  z-index: 100;
}
.loading-page h4 {
  z-index: 100;
  color: #FFFFFF;
}