.reset__password {
  width: 30rem;
  height: 10rem;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
}
.reset__password h2 {
  color: white !important;
}
.reset__input {
  width: 100%;
  padding: 0.5rem;
  display: flex;
}
.reset__input input {
  background-color: rgba(128, 128, 128, 0.239) !important;
  color: #FFF;
  font-weight: bolder;
}
.reset__input button {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}
.reset__input button.disabled {
  opacity: 0.7;
}