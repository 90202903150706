.reuse__analysis {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 1rem;
  border-radius: 0.4rem;
}
.reuse__analysis--left {
  flex: 2;
  height: 100%;
  display: flex;
  flex-direction:column;
  gap: 1rem;
}
.reuse__analysis--left-top {
  flex: 1;
  display: flex;
  gap: 1rem;
}




.reuse__analysis--left-bottom {
  flex: 2;
  display: flex;
  gap: 1rem;
}
.reuse__analysis--left-bottom-left {
  display: flex;
  flex: 1;
  gap: 1rem;
}
.reuse__analysis--left-bottom-right {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1rem;
}
.reuse__analysis--right {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.reuse__analysis h2 {
  font-size: 3rem;
}
.reuse__analysis h4 {
  font-size: 1.4rem;
}
.reuse__analysis h2, .reuse__analysis h4, .reuse__analysis p,.reuse__analysis h5 {

  padding-left: 0.5rem;
  padding-top: 0.5rem;
  letter-spacing: 1px;
}
/* frequency */
.reuse__frequency {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.reuse__frequency--data-container {
  width: 100%;
  display: flex;
  height: 100%;
}
.reuse__frequency--data {
  height: 100%;
  padding: 0.5rem;
  flex: 1;
  display: flex;
  justify-content:center;
  align-items: flex-start;
  flex-direction: column;
}
.reuse__frequency--data h5 span {
  color: green;
}
.reuse__frequency--data h5 {
  font-size: 1.2rem;
}
/* pending complete */
.reuse__pending--complete {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.pending__complete--container {
  height: calc(100% - 2rem);
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.reuse__budget--data {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}
.reuse__analysis--graph {
  max-width: 100%;
  height: 100% !important;
  overflow: hidden;
  display: flex;
  justify-content: center;
}
.reuse__analysis--graph canvas {
  height: calc(100% - 1.5rem) !important;
  width: 95% !important;
}


.reuse__analysis--status {
  width: 100%;
  height: calc(100% - 2rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem;
}
.reuse__analysis--status--bar {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.reuse__status--bar-head {
  display: flex;
  justify-content: space-between;
  padding-right: 0.5rem;

}
.reuse__analysis--status--bar .status__bar--container {
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-left: none;
  height: 0.5rem;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.status__bar {
  background-color: var(--color-blue);
  height: 100%;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

@media screen and (max-width:  1200px) {
  .reuse__analysis {
    flex-direction: column;
  }
  .reuse__analysis--right {
    flex-direction: row;
    min-height: 20rem;
  }
}
@media screen and (max-width:  850px) {
  .reuse__analysis--left-bottom {
    flex-direction: column;
  }
  .reuse__analysis--left {
    height: unset;
  }
  .reuse__analysis--left-top {
    flex-direction: column;
  }
  .reuse__analysis h2 {
    font-size: 2.2rem;
  }
  .reuse__analysis h4 {
    font-size: 1rem;
  }
  .reuse__pending--complete {
    padding: 00.5rem;
  }
  .reuse__analysis--right {
    flex-direction: row;
    min-height: unset;
  }
  .reuse__analysis--graph {
    min-height: unset;
  }

}
@media screen and (max-width:  550px) {
  .reuse__analysis--right {
    flex-direction: column;
  }

}