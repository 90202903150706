@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');
:root{
    --font-family: 'Manrope', sans-serif;
    --price-plan-font: 'Montserrat', sans-serif;
    --text-color: #606060;
    --clr-orange: #F1C232;
    --color-bg: #f5f6f8;
    --color-footer : #031B34;
    --color-blog: #042c54;
    --color-text: #686869;
    --color-blue: #0156ab;
    --gradient-text: linear-gradient(89.97deg, #042c54 1.84%, #535352 102.67%);
    --gradient-text--blue: linear-gradient(89.97deg, #F1C232 1.84%, #535352 102.67%);
    --gradient-text--white: linear-gradient(89.97deg,  rgb(247, 246, 246) 1.84%, #ffffff 102.67%);
    --gradient-bar: linear-gradient(103.22deg, #F1C232 -13.86%, #042c54 99.55%);
    --gradient-bar-red: linear-gradient(103.22deg, rgb(247, 59, 59) -13.86%, #eedcd2 99.55%);
    --speed: 500ms;
    --shadow:rgba(0, 0, 0, 0) 0px 0 0;    
 }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  background-color: var(--color-mode-main);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

select{
  width: 100%;
  border: none;
  outline: none;
  height: 2rem;
  cursor: pointer;
  background-color: var(--color-mode-main) !important;
  opacity: 0.7;
  color: var(--text-color);
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background-color: var(--color-blue); 
  color: var(--clr-primary);
  cursor: pointer;
  transition: var(--speed);
  border-radius: 0.2rem;
}
input[type="date"]::-webkit-calendar-picker-indicator:hover {
  opacity: 0.8;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Chrome, Safari, Edge */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

textarea, input{
  color: var(--text-color);
  width: 100%;
  height: 2rem;
  border: none;
  outline: none;
  resize: none;
  background-color: transparent;
  font-size: 17px;
  font-family: var(--font-family);
  background-color: var(--color-mode-main) !important;
  opacity: 0.7;
}

.currency-input {
  display: grid;
  width: 100%;
  grid-template-columns: 1rem 1fr;
  align-items: center;
}

.shake {
  animation: shake 0.2s ease-in-out;
}

@keyframes shake {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(-5px, 0);
  }
  50% {
    transform: translate(0);
  }
  75% {
    transform: translate(5px, 0);
  }
  100% {
    transform: translate(0);
  }
}

.gradient__bg {
  background:radial-gradient(circle at 3% 25%, #042c54 0%, #031B34 100%);
}
.gradient__bg--light {
  background:radial-gradient(circle at 3% 25%, #01468b 0%, #0156ab 100%);
}


.tile__chosen {
  background: var(--color-tile-secondary);
}

.tile__bg {
  background:var(--color-mode-secondary);
}
.page__bg {
  background-color: var(--color-bg);
}
.flex__column {
  display: flex;
  flex-direction: column;
}
.gap25 {
  gap: 0.25rem;
}
.gap5 {
  gap: 0.5rem;
}
.gap75 {
  gap: 0.75rem;
}
.gap1 {
  gap: 1rem;
}
.br1 {
  border-radius: 1rem;
}
.br04 {
  border-radius: 0.4rem;
}
.flex__between {
  display: flex;
  justify-content: space-between;

}

button {
  outline: none;
  border: none;
  cursor: pointer;
  background-color: #0156ab;
  color: #FFF;
}




.gradient__bg--white {
  background:radial-gradient(circle at 3% 25%, rgb(247, 246, 246) 0%, #ffffff 100%);
}

.gradient__bg--gold {
  background:radial-gradient(circle at 3% 25%, #F1C232 0%, #d5a513 100%);
}

.gradient__text {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.gradient__text--blue {
  background: var(--gradient-text--blue);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.gradient__text--white {
  background: var(--gradient-text--white);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section__padding {
  padding: 4rem 6rem;
}

.section__margin {
  padding: 4rem 6rem;
}

.scale-up-center {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
  